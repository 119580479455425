<script>
import options from "./Tooltip.options"
export default options
</script>

<template>
  <div class="nyk-tooltip__container" aria-live="polite">
    <button
      @click="showDialog"
      @keydown.enter="showDialog"
      aria-haspopup="true"
      :aria-label="tooltipAccessibleName"
      tabindex="0"
      class="nyk-tooltip__tooltip-button"
      :class="{ 'nyk-tooltip__tooltip-button--active': display }"
      ref="highlighted">
      i
    </button>

    <template v-if="display">
      <div class="nyk-tooltip" aria-tooltip ref="wordPopup">
        <div class="nyk-tooltip__triangle" ref="arrow"></div>
        <p class="nyk-tooltip__definition" v-html="tooltip"></p>
      </div>
    </template>
  </div>
</template>
