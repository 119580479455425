import { ref, onMounted, h } from "vue"
import DynamicTable from "../DynamicTable/DynamicTable.vue"
import FilterTabs from "../FilterTabs/FilterTabs.vue"

export default {
  name: "AnnualReportsTable",
  components: {
    DynamicTable,
    FilterTabs,
  },
  props: {
    groups: {
      type: Array,
      required: true
    },
    reports: {
      type: Array,
      required: true
      },
    title: {
        type: String,
        required: true
      },
    subtitle: {
        type: String,
        required: true
    } 
  },
  setup(props) {
    const activeYear = ref(props.groups[0] ?? null)
    const tabs = ref([])
    const tables = ref([])

    const getDocumentTypes = () => {
      const documentTypes = []

      props.reports.map((report) => {
        if (
          documentTypes.length === 0 ||
          documentTypes.find(
            (item) => item.documentType === report.documentType
          ) === undefined
        ) {
          documentTypes.push({
            documentType: report.documentType,
            documentTypeFriendlyName: report.documentTypeFriendlyName,
          })
        }
      })

      return documentTypes
    }

    const getFilteredRows = (group, documentType) => {
      let rows = []

      const filteredReports = props.reports.filter(
        (report) => report.year === group
      )

      filteredReports
        .filter((report) => report.documentType === documentType)
        .forEach((report) => {
          rows.push([
            { name: report.title, href: report.downloadLink },
            {
              name: "",
              href: report.downloadLink,
              downloadIcon: true,
              styles:
                "nyk-table__container__table__body__row__item--text-right",
            },
          ])
        })
      return rows
    }

    onMounted(() => {
      const documentTypes = getDocumentTypes()

      props.groups.map((group) => {
        tabs.value.push({
          title: group.toString(),
          id: group.toString(),
        })

        documentTypes.forEach((item) => {
          const rows = getFilteredRows(group, item.documentType)
          if (rows.length > 0) {
            tables.value.push({
              year: group,
              headers: [{ name: item.documentTypeFriendlyName }],
              rows: rows,
            })
          }
        })
      })
    })

    const changeFilter = (year) => {
      activeYear.value = parseInt(year)
    }

    return {
      activeYear,
      changeFilter,
      tabs,
      tables,
    }
  },
}
