export default {
  name: "DynamicTable",
  props: {
    headers: {
      type: Object,
      required: true,
    },
    rows: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {}
  },
}
