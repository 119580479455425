import { onMounted, ref } from "vue"
import FilterTabs from "../FilterTabs/FilterTabs.vue"
import SearchInputField from "../SearchInputField/SearchInputField.vue"
import DynamicTable from "../DynamicTable/DynamicTable.vue"

export default {
  name: "InvestmentFundTable",
  components: {
    FilterTabs,
    SearchInputField,
    DynamicTable,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    model: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const fundListType = Object.freeze({
      Nykredit: "internal",
      External: "external",
    })

    const searchInput = ref("")
    const activeFilter = ref(fundListType.Nykredit)
    const tabs = ref([])
    const tableHeaders = ref([])
    const tableRows = ref([])

    const setTabs = () => {
      tabs.value.push({
        title: props.model.internalInvestmentFundsTabLabel,
        id: fundListType.Nykredit,
      })
      tabs.value.push({
        title: props.model.externalInvestmentFundsTabLabel,
        id: fundListType.External,
      })
    }

    const setTableHeaders = () => {
      tableHeaders.value.push(
        { name: props.model.isinLabel },
        { name: props.model.investmentNameLabel },
        {
          name: props.model.fppLabel,
          styles: "nyk-table__container__table__header__row__item--text-right",
        }
      )
    }

    const setTableRows = (data) => {
      data.map((row) => {
        tableRows.value.push([
          { name: row.isin },
          { name: row.investmentName },
          {
            name: row.fpp,
            styles: "nyk-table__container__table__body__row__item--text-right",
          },
        ])
      })
    }

    onMounted(() => {
      if (props.model) {
        setTabs()
        setTableHeaders()
        fetchInvestmentFunds()
      }
    })

    const fetchInvestmentFunds = async () => {
      // URL FOR PRODUCTION
       const url = `${props.apiUrl}?documentId=${
         props.model.documentId
       }&useNykreditFunds=${
         activeFilter.value === fundListType.Nykredit
       }&useExternalFunds=${
         activeFilter.value === fundListType.External
       }&searchTerm=${searchInput.value}`

      // URL FOR LOCAL TESTING
      // let url = ""
      // if (searchInput.value === "") {
      //  url = `/data/investmentFunds/${activeFilter.value}FundList.json`
      // } else {
      //  url = `/data/investmentFunds/searchFundList.json`
      // }

      const request = new Request(url)

      fetch(request)
        .then((res) => {
          if (res.ok) {
            return res.json()
          } else {
            throw new Error("Didn't work")
          }
        })
        .then((json) => {
          tableRows.value = []
          setTableRows(json)
        })
        .catch((err) => {
          console.error(err)
        })
    }

    const inputEnter = (value) => {
      searchInput.value = value
      fetchInvestmentFunds()
    }

    const inputChanged = (value) => {
      searchInput.value = value
      if (value === "") {
        fetchInvestmentFunds()
      }
    }

    const filterChanged = (value) => {
      activeFilter.value = value
      fetchInvestmentFunds()
    }

    return {
      searchInput,
      inputEnter,
      inputChanged,
      filterChanged,
      activeFilter,
      tabs,
      tableHeaders,
      tableRows,
    }
  },
}
