<script>
  import options from './SiteSearchingResult.options'
  export default options
</script>


<template>
  <li class="nyk-site-searching-result">
    <a :href="href" class="nyk-site-searching-result__link">
      <div class="nyk-site-searching-result__link__tags">
        <span class="nyk-site-searching-result__link__tags__tag">{{ tag }}</span>
        <template v-if="filename">
          <span class="nyk-site-searching-result__link__tags__tag nyk-site-searching-result__link__tags__tag--file">{{ getFileExtension }}</span>
        </template>
      </div>
      <h3 class="nyk-site-searching-result__link__title">{{ title }}</h3>
      <p class="nyk-site-searching-result__link__description">{{ description }}</p>

      <div class="nyk-site-searching-result__link__arrow">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12"><path fill="#07094A" d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z"/></svg>
      </div>
    </a>
  </li>
</template>
