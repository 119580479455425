import { computed, ref } from "vue"

export default {
  name: "MultiSelectDropdown",
  props: {
    initOptions: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    placeholderLabel: {
      type: String,
      required: false,
    },
    onChangeCallback: {
      type: Function,
      required: false,
    },
    showArrow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const options = ref(props.initOptions)
    const popupOpen = ref(false)

    const onChangeDropdown = (e) => {
      const elements = e.currentTarget.elements
      options.value = []
      Array.from(elements).forEach((element) => {
        options.value.push({
          value: element.value,
          name: element.dataset.name,
          selected: element.checked,
        })
      })

      if (props.onChangeCallback) {
        props.onChangeCallback(options.value)
      }
    }

    const selectedValues = computed(() => {
      let res = ""
      options.value.forEach((option) => {
        if (option.selected) {
          if (res === "") {
            res += option.name
          } else {
            res += ", " + option.name
          }
        }
      })

      if (res === "") {
        res = props.placeholderLabel ?? ""
      }
      return res
    })

    const optionsIsNotEmpty = computed(() => {
      const numOfSelectedOptions = options.value.filter((option) => {
        return option.selected
      }).length
      return numOfSelectedOptions > 0
    })

    const toggleDropdown = () => {
      popupOpen.value = !popupOpen.value

      document.addEventListener("click", function closeDropdown(e) {
        const insideMuliSelectDropdown = e.target.closest(".nyk-js--multi-select-dropdown")
        if (!insideMuliSelectDropdown) {
          popupOpen.value = false
          document.removeEventListener("click", closeDropdown)
        }
      })
    }

    const emptySelectedResults = () => {
      options.value.forEach((option) => {
        option.selected = false
      })
      if (props.onChangeCallback) {
        props.onChangeCallback(options.value)
      }
    }

    return {
      onChangeDropdown,
      toggleDropdown,
      selectedValues,
      options,
      popupOpen,
      emptySelectedResults,
      optionsIsNotEmpty,
    }
  },
}
