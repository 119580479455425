<script>
import options from "./JobApplication.options"
export default options
</script>

<template>
  <template v-if="loading">
    <div class="nyk-loader">
      <div class="nyk-loader__content">
        <div class="nyk-loader__content__circle"></div>
      </div>
    </div>
  </template>

  <template v-if="submitSuccess">
    <div class="nyk-hero-topic-pages">
      <strong class="nyk-hero-topic-pages__subject">{{ subject }}</strong>
      <h1 class="nyk-hero-topic-pages__title nyk-hero-topic-pages__title--big">
        {{ successLabels.title }}
      </h1>
      <div class="nyk-hero-topic-pages__description nyk-richtext">
        <p>{{ successLabels.description }}</p>
      </div>
      <a
        :href="successLabels.link.url"
        class="nyk-hero-topic-pages__action nyk-button nyk-button--icon nyk-button--filled-primary">
        {{ successLabels.link.text }}
        <div class="nyk-button__icon">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
            <path
              fill="#07094A"
              d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z" />
          </svg>
        </div>
      </a>
    </div>
  </template>

  <template v-else>
    <div class="nyk-hero-topic-pages">
      <strong class="nyk-hero-topic-pages__subject">{{ subject }}</strong>
      <h1 class="nyk-hero-topic-pages__title">{{ title }}</h1>
      <div class="nyk-hero-topic-pages__description nyk-richtext">
        <p>{{ description }}</p>
      </div>
    </div>

    <div class="nyk-job-application">
      <div class="nyk-job-application__content">
        <article ref="jobApplicationRef">
          <form
            class="nyk-job-application__content__form"
            v-on:submit.prevent="handleSubmitJobApplication"
            ref="formRef">
            <div class="nyk-form-layout">
              <div class="nyk-form-layout__row">
                <div class="nyk-form nyk-js--form" ref="firstNameWrapper">
                  <div class="nyk-form__content">
                    <input
                      class="nyk-form__content__input"
                      type="given-name"
                      maxlength="10000"
                      autocomplete="given-name"
                      id="firstname"
                      name="firstname"
                      aria-required="true"
                      v-model="firstNameModel"
                      :placeholder="labels.inputFirstNameLabel" />
                    <label for="firstname" class="nyk-form__content__label">{{
                      labels.inputFirstNameLabel
                    }}</label>
                    <button
                      v-on:click="resetInputValue"
                      type="button"
                      class="nyk-form__content__reset-icon nyk-form-js--reset"
                      aria-label="reset input value">
                      <span class="nyk-icon nyk-icon--ui-close"></span>
                    </button>
                    <div class="nyk-form__content__error-icon">
                      <span class="nyk-icon nyk-icon--ui-form-error"></span>
                    </div>
                  </div>
                  <span class="nyk-form__help-message"></span>
                  <span class="nyk-form__error-message">{{
                    labels.inputFirstNameErrorMessage
                  }}</span>
                </div>

                <div class="nyk-form nyk-js--form" ref="lastNameWrapper">
                  <div class="nyk-form__content">
                    <input
                      class="nyk-form__content__input"
                      type="text"
                      maxlength="10000"
                      autocomplete="family-name"
                      id="lastname"
                      name="lastname"
                      aria-required="true"
                      v-model="lastNameModel"
                      :placeholder="labels.inputLastNameLabel" />
                    <label for="lastname" class="nyk-form__content__label">{{
                      labels.inputLastNameLabel
                    }}</label>
                    <button
                      v-on:click="resetInputValue"
                      type="button"
                      class="nyk-form__content__reset-icon nyk-form-js--reset"
                      aria-label="reset input value">
                      <span class="nyk-icon nyk-icon--ui-close"></span>
                    </button>
                    <div class="nyk-form__content__error-icon">
                      <span class="nyk-icon nyk-icon--ui-form-error"></span>
                    </div>
                  </div>
                  <span class="nyk-form__help-message"></span>
                  <span class="nyk-form__error-message">{{
                    labels.inputLastNameErrorMessage
                  }}</span>
                </div>
              </div>

              <div class="nyk-form nyk-js--form" ref="addressWrapper">
                <div class="nyk-form__content">
                  <input
                    class="nyk-form__content__input"
                    type="text"
                    id="address"
                    name="address"
                    autocomplete="street-address"
                    aria-required="true"
                    v-model="addressModel"
                    :placeholder="labels.inputAddressLabel" />
                  <label for="address" class="nyk-form__content__label">{{
                    labels.inputAddressLabel
                  }}</label>
                  <button
                    v-on:click="resetInputValue"
                    type="button"
                    class="nyk-form__content__reset-icon nyk-form-js--reset"
                    aria-label="reset input value">
                    <span class="nyk-icon nyk-icon--ui-close"></span>
                  </button>
                  <div class="nyk-form__content__error-icon">
                    <span class="nyk-icon nyk-icon--ui-form-error"></span>
                  </div>
                </div>
                <span class="nyk-form__help-message"></span>
                <span class="nyk-form__error-message">{{ labels.inputAddressErrorMessage }}</span>
              </div>

              <div class="nyk-form-layout__row">
                <div class="nyk-form nyk-js--form" ref="postalCodeWrapper">
                  <div class="nyk-form__content">
                    <input
                      class="nyk-form__content__input"
                      type="number"
                      maxlength="4"
                      autocomplete="postal-code"
                      id="postalcode"
                      name="postalcode"
                      aria-required="true"
                      v-model="postalCodeModel"
                      :placeholder="labels.inputZipcodeLabel" />
                    <label for="postalcode" class="nyk-form__content__label">{{
                      labels.inputZipcodeLabel
                    }}</label>
                    <button
                      v-on:click="resetInputValue"
                      type="button"
                      class="nyk-form__content__reset-icon nyk-form-js--reset"
                      aria-label="reset input value">
                      <span class="nyk-icon nyk-icon--ui-close"></span>
                    </button>
                    <div class="nyk-form__content__error-icon">
                      <span class="nyk-icon nyk-icon--ui-form-error"></span>
                    </div>
                  </div>
                  <span class="nyk-form__help-message"></span>
                  <span class="nyk-form__error-message">{{ labels.inputZipcodeErrorMessage }}</span>
                </div>

                <div class="nyk-form nyk-js--form" ref="cityWrapper">
                  <div class="nyk-form__content">
                    <input
                      class="nyk-form__content__input"
                      type="text"
                      maxlength="10000"
                      autocomplete="off"
                      id="city"
                      name="city"
                      aria-required="true"
                      v-model="cityModel"
                      :placeholder="labels.inputCityLabel" />
                    <label for="city" class="nyk-form__content__label">{{
                      labels.inputCityLabel
                    }}</label>
                    <button
                      v-on:click="resetInputValue"
                      type="button"
                      class="nyk-form__content__reset-icon nyk-form-js--reset"
                      aria-label="reset input value">
                      <span class="nyk-icon nyk-icon--ui-close"></span>
                    </button>
                    <div class="nyk-form__content__error-icon">
                      <span class="nyk-icon nyk-icon--ui-form-error"></span>
                    </div>
                  </div>
                  <span class="nyk-form__help-message"></span>
                  <span class="nyk-form__error-message">{{ labels.inputCityErrorMessage }}</span>
                </div>
              </div>

              <div class="nyk-form-layout__row">
                <div class="nyk-form nyk-js--form" ref="emailWrapper">
                  <div class="nyk-form__content">
                    <input
                      class="nyk-form__content__input"
                      type="email"
                      maxlength="10000"
                      autocomplete="email"
                      id="email"
                      name="email"
                      aria-required="true"
                      v-model="emailModel"
                      :placeholder="labels.inputEmailLabel" />
                    <label for="email" class="nyk-form__content__label">{{
                      labels.inputEmailLabel
                    }}</label>
                    <button
                      v-on:click="resetInputValue"
                      type="button"
                      class="nyk-form__content__reset-icon nyk-form-js--reset"
                      aria-label="reset input value">
                      <span class="nyk-icon nyk-icon--ui-close"></span>
                    </button>
                    <div class="nyk-form__content__error-icon">
                      <span class="nyk-icon nyk-icon--ui-form-error"></span>
                    </div>
                  </div>
                  <span class="nyk-form__help-message"></span>
                  <span class="nyk-form__error-message" ref="emailErrorMessage">{{
                    labels.inputEmailErrorMessage
                  }}</span>
                </div>

                <div class="nyk-form nyk-js--form" ref="phoneWrapper">
                  <div class="nyk-form__content">
                    <input
                      class="nyk-form__content__input"
                      type="tel"
                      maxlength="11"
                      autocomplete="tel"
                      id="phone"
                      name="phone"
                      aria-required="true"
                      v-model="phoneModel"
                      :placeholder="labels.inputTelephoneLabel" />
                    <label for="phone" class="nyk-form__content__label">{{
                      labels.inputTelephoneLabel
                    }}</label>
                    <button
                      v-on:click="resetInputValue"
                      type="button"
                      class="nyk-form__content__reset-icon nyk-form-js--reset"
                      aria-label="reset input value">
                      <span class="nyk-icon nyk-icon--ui-close"></span>
                    </button>
                    <div class="nyk-form__content__error-icon">
                      <span class="nyk-icon nyk-icon--ui-form-error"></span>
                    </div>
                  </div>
                  <span class="nyk-form__help-message"></span>
                  <span class="nyk-form__error-message">{{
                    labels.inputTelephoneErrorMessage
                  }}</span>
                </div>
              </div>

              <div
                class="nyk-form nyk-js--form nyk-form-choice nyk-job-application__form__gender"
                ref="genderWrapper"
                v-on:change="genderUpdated">
                <div class="nyk-form-choice__label">{{ labels.inputGenderLabel }}</div>
                <div class="nyk-form-choice__content">
                  <template v-for="(item, index) in genderList" :key="index">
                    <div class="nyk-form-choice__content__item nyk-js--choice-wrapper">
                      <div class="nyk-form-choice__content__item__choice">
                        <input
                          class="nyk-form-choice__content__item__choice__input"
                          :id="item.code"
                          type="radio"
                          :value="item.code"
                          name="gender" />
                        <div class="nyk-form-choice__content__item__choice__circle">
                          <div class="nyk-form-choice__content__item__choice__circle__outer"></div>
                          <div class="nyk-form-choice__content__item__choice__circle__inner"></div>
                        </div>
                      </div>
                      <label class="nyk-form-choice__content__item__label" :for="item.code">{{
                        item.name
                      }}</label>
                    </div>
                  </template>
                </div>

                <span class="nyk-form-choice__help-message"></span>
                <span class="nyk-form__error-message">{{ labels.inputGenderErrorMessage }}</span>
              </div>

              <div class="nyk-job-application__form__section">
                <h3 class="nyk-fs-heading--medium">Upload dokumenter</h3>
                <div class="nyk-fs-text--medium">{{ labels.uploadFileLabel }}</div>
              </div>

              <div class="nyk-form-layout">
                <div class="nyk-form nyk-js--form nyk-js--cv-files" ref="cvFileWrapper">
                  <label class="nyk-form__file">
                    <input
                      class="nyk-form__file__input"
                      name="cvfile"
                      id="cvfile"
                      type="file"
                      ref="cvFileInput"
                      :accept="acceptedFileTypes"
                      v-on:change="handleSingleFileUpload" />
                    <div class="nyk-form__file__icon">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 29 29">
                        <g stroke="#07094A" stroke-miterlimit="10" stroke-width="2">
                          <path
                            stroke-linecap="square"
                            d="M1 20v5.333c0 .708.284 1.386.79 1.886.507.5 1.194.781 1.91.781h21.6c.716 0 1.403-.281 1.91-.781.505-.5.79-1.178.79-1.886V20" />
                          <path d="M14.5 0v20" />
                          <path stroke-linecap="square" d="M7.75 13.333 14.5 20l6.75-6.667" />
                        </g>
                      </svg>
                    </div>
                    <div class="nyk-form__file__label">{{ labels.inputCvLabel }}</div>
                  </label>
                  <ul
                    class="nyk-form__files nyk-js--files"
                    v-on:click="fileRemoved"
                    ref="cvUploadedFiles"></ul>
                  <span class="nyk-form__error-message" ref="cvFilesErrorMessage">{{
                    labels.inputCvErrorMessage
                  }}</span>
                </div>

                <div class="nyk-form nyk-js--form" ref="applicationFilesWrapper">
                  <label class="nyk-form__file">
                    <input
                      class="nyk-form__file__input"
                      name="applicationfile"
                      id="applicationfile"
                      type="file"
                      ref="applicationFileInput"
                      :accept="acceptedFileTypes"
                      v-on:change="handleSingleFileUpload" />
                    <div class="nyk-form__file__icon">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 29 29">
                        <g stroke="#07094A" stroke-miterlimit="10" stroke-width="2">
                          <path
                            stroke-linecap="square"
                            d="M1 20v5.333c0 .708.284 1.386.79 1.886.507.5 1.194.781 1.91.781h21.6c.716 0 1.403-.281 1.91-.781.505-.5.79-1.178.79-1.886V20" />
                          <path d="M14.5 0v20" />
                          <path stroke-linecap="square" d="M7.75 13.333 14.5 20l6.75-6.667" />
                        </g>
                      </svg>
                    </div>
                    <div class="nyk-form__file__label">{{ labels.inputApplicationLabel }}</div>
                  </label>
                  <ul class="nyk-form__files nyk-js--files" v-on:click="fileRemoved"></ul>
                  <span class="nyk-form__error-message" ref="applicationFilesErrorMessage"></span>
                </div>

                <div class="nyk-form nyk-js--form" ref="miscFilesWrapper">
                  <label class="nyk-form__file">
                    <input
                      class="nyk-form__file__input"
                      name="miscfiles"
                      id="miscfiles"
                      type="file"
                      multiple
                      ref="miscFilesInput"
                      :accept="acceptedFileTypes"
                      v-on:change="handleFileUploadWithLimit" />
                    <div class="nyk-form__file__icon">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 29 29">
                        <g stroke="#07094A" stroke-miterlimit="10" stroke-width="2">
                          <path
                            stroke-linecap="square"
                            d="M1 20v5.333c0 .708.284 1.386.79 1.886.507.5 1.194.781 1.91.781h21.6c.716 0 1.403-.281 1.91-.781.505-.5.79-1.178.79-1.886V20" />
                          <path d="M14.5 0v20" />
                          <path stroke-linecap="square" d="M7.75 13.333 14.5 20l6.75-6.667" />
                        </g>
                      </svg>
                    </div>
                    <div class="nyk-form__file__label">{{ labels.inputMiscDocLabel }}</div>
                  </label>
                  <ul class="nyk-form__files nyk-js--files" v-on:click="fileRemoved"></ul>
                  <span class="nyk-form__error-message" ref="miscFilesErrorMessage"></span>
                </div>
              </div>
            </div>

            <button
              type="submit"
              :disabled="loading"
              class="nyk-job-application__content__form__button nyk-button nyk-button--icon nyk-button--filled-primary">
              {{ labels.jobApplicationButtonLabel }}
              <div class="nyk-button__icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 12">
                  <path
                    fill="#07094A"
                    d="M0 5.393v1.231h11.37l-3.898 4.243.67.633L14 5.991 8.142.5l-.67.633 3.898 4.26H0Z" />
                </svg>
              </div>
            </button>

            <div
              v-if="formFailedErrorMessage !== ''"
              class="nyk-job-application__content__form__error-text nyk-fs-text--small">
              <span>{{ formFailedErrorMessage }}</span>
              <span class="nyk-icon nyk-icon--ui-form-error"></span>
            </div>
          </form>
        </article>
      </div>
    </div>
  </template>
</template>
