import { createApp } from "vue"
import SignupMultipleNewsletters from "./SignupMultipleNewsletters"
import isBoolean from "../../../../../Frontend/src/foundation/js/inputValidators/isBoolean"

const el = ".nyk-component--signup-multiple-newsletters"

export default function (Store) {
  const DOM = document.querySelectorAll(el)
  if (DOM.length) {
    DOM.forEach((SignupMultipleNewslettersDOM) => {
      const newsletters = SignupMultipleNewslettersDOM.dataset.newsletters
        ? JSON.parse(SignupMultipleNewslettersDOM.dataset.newsletters)
        : []

      const newslettersUserInputs = newsletters.map((newsletter) => {
        return {
          field: newsletter.name,
          value: false,
          validator: isBoolean,
          isValid: false,
        }
      })

      const props = {
        labels: JSON.parse(SignupMultipleNewslettersDOM.dataset.labels),
        apiUrl: SignupMultipleNewslettersDOM.dataset.apiUrl,
        newsletters: newsletters,
        newslettersUserInputs: newslettersUserInputs,
      }

      const component = createApp(SignupMultipleNewsletters, props).use(Store)
      component.mount(SignupMultipleNewslettersDOM)
    })
  }
}
