import { computed, ref } from "vue"

export default {
  name: "Tooltip",
  props: {
    tooltip: {
      type: String,
      required: true,
    },
    tooltipName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const display = ref(false)

    const tooltipAccessibleName = computed(() => {
      return props.tooltipName ?? ""
    })

    const setESChandler = () => {
      const handler = (event) => {
        if (event.key === "Escape") {
          closeDialog()
          document.body.removeEventListener("keydown", handler)
        }
      }
      document.body.addEventListener("keydown", handler)
    }

    const highlighted = ref(null)
    const wordPopup = ref(null)
    const arrow = ref(null)

    const clickOutsideHandler = function (e) {
      if (wordPopup.value.contains(e.target)) return false
      e.preventDefault()
      closeDialog()
    }

    const tapTrap = (e) => {
      e.preventDefault()
      e.stopPropagation()
      closeDialog()
      highlighted.value.focus()
    }

    const showDialog = () => {
      setESChandler()

      if (!display.value) {
        display.value = true

        setTimeout(() => {
          const wordPopupRect = wordPopup.value.getBoundingClientRect()

          if (wordPopupRect.right + 40 > window.innerWidth) {
            wordPopup.value.style.marginLeft = `-${wordPopupRect.right + 20 - window.innerWidth}px`
            arrow.value.style.left = `calc(50% + ${wordPopupRect.right + 20 - window.innerWidth}px)`
          }

          document.body.addEventListener("click", clickOutsideHandler)
        }, 10)
      }
    }

    const closeDialog = () => {
      document.body.removeEventListener("click", clickOutsideHandler)
      display.value = false
    }

    return {
      arrow,
      closeDialog,
      display,
      highlighted,
      showDialog,
      tapTrap,
      wordPopup,
      tooltipAccessibleName,
    }
  },
}
