<script>
import options from "./SignupStepEmail.options"
export default options
</script>

<template>
    <article class="nyk-signup-step">
        <div class="nyk-signup-step__content">
            <div
                class="nyk-signup-step__content__section nyk-signup-step__content__section--half-width nyk-signup-step__content__section--full-width-mobile">
                <div class="nyk-signup-step__header">
                    <h2 class="nyk-signup-step__title">{{ currentStepTexts.title }}</h2>
                    <p class="nyk-signup-step__stepcounter--mobile">{{ stepCounterText }}</p>
                </div>
            </div>

            <div
                class="nyk-signup-step__summary nyk-signup-step__content__section--half-width nyk-signup-step__content__section--hide-mobile"
                v-html="currentStepTexts.summary"></div>

            <div
                class="nyk-signup-step__mt-auto nyk-signup-step__content__section nyk-signup-step__content__section--full-width nyk-signup-step__content__section--space-between">
                <div
                    aria-live="polite"
                    class="nyk-signup-step__content__section nyk-signup-step__content__section--full-width nyk-signup-step__general-input">
                    <input
                        class="nyk-signup-step__input"
                        placeholder="E-mail"
                        type="email"
                        autocomplete="email"
                        v-model="emailInput"
                        v-on:keyup.enter="nextStepCallback([{ field: 'email', value: emailInput }])" />

                    <p class="nyk-signup-step__input-error-msg">
                        <template v-if="displayInputError"
                            >Indtast venligst en email adresse <span class="nyk-icon nyk-icon--ui-form-error--primary"></span
                        ></template>
                    </p>
                </div>

                <div class="nyk-signup-step__summary nyk-signup-step__summary--mobile" v-html="currentStepTexts.summary"></div>

                <SignupStepActions
                    :previousStepCallback="previousStepCallback"
                    :nextStepCallback="() => nextStepCallback([{ field: 'email', value: emailInput }])"
                    :nextStepButtonText="nextStepButtonText"
                    :stepCounterText="stepCounterText"
                    :hasPreviousStep="hasPreviousStep" />
            </div>
        </div>
    </article>
</template>
