import { computed } from "vue"
import DynamicTable from "../DynamicTable/DynamicTable.vue"
import { useStore } from "vuex"

export default {
  name: "JobPostingsTable",
  components: {
    DynamicTable,
  },
  props: {
    numberOfResults: {
      type: Number,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore()

    const headers = computed(() => {
      return store.state.jobs.headers
    })

    const rows = computed(() => {
      const array = []
      const storeRows = store.state.jobs.rows
      const redirectUrl = store.state.jobs.redirectUrl

      storeRows.map((row) => {
        const updatedRow = []
        row.map((cell) => {
          updatedRow.push({
            name: cell.name,
            href: cell.reference
              ? `${redirectUrl}?jobId=${cell.reference}`
              : "",
            styles: cell.styles ?? "",
          })
        })
        array.push(updatedRow)
      })

      return array
    })

    const filters = computed(() => {
      return store.state.jobs.filters
    })

    const filterChanged = (e) => {
      store.dispatch("jobs/filteredRows", {
        api: props.apiUrl,
        filter: e.target.value,
      })
    }

    return {
      headers,
      rows,
      filters,
      filterChanged,
    }
  },
}
