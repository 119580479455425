<script>
import options from "./DynamicDocumentList.options"
export default options
</script>

<template>
  <article class="nyk-document-list nyk-dynamic-document-list">
    <div v-if="title && description" class="nyk-document-list__header">
      <h2 v-if="title" class="nyk-document-list__header__title">
        {{ title }}
      </h2>

      <p v-if="description" class="nyk-document-list__header__description">
        {{ description }}
      </p>
    </div>

    <template v-for="(list, index) in documentLists" :key="index">
      <div class="nyk-document-list__content">
        <div class="nyk-document-list__content__group">
          <div class="nyk-document-list__content__group__header">
            <strong class="nyk-document-list__content__group__header__title">
              {{ list.title }}
            </strong>
          </div>

          <ul
            v-if="list.items.length > 0"
            class="nyk-document-list__content__group__list">
            <template v-for="(item, index) in list.items" :key="index">
              <li class="nyk-document-list__content__group__list__item">
                <a
                  :href="item.href"
                  class="nyk-document-list__content__group__list__item__link">
                  <div
                    class="nyk-document-list__content__group__list__item__link__filetype">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 30 30">
                      <path
                        stroke="#07094A"
                        stroke-miterlimit="10"
                        stroke-width="2"
                        d="M18.733 1v7.467H26.2" />
                      <path
                        stroke="#07094A"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="2"
                        d="M26.2 12.2V8.467L18.735 1H3.801v11.2M3.8 25.267V29h22.4v-3.733" />
                      <path
                        fill="#07094A"
                        d="M12.334 17.898c0 .596-.176 1.056-.527 1.38-.35.325-.85.487-1.497.487h-.405v1.768h-1.44v-5.33h1.845c.673 0 1.179.147 1.517.442.338.294.507.71.507 1.253Zm-2.429.694h.262c.217 0 .389-.061.516-.182.128-.122.192-.29.192-.503 0-.36-.2-.54-.599-.54h-.371v1.224ZM17.706 18.755c0 .89-.244 1.575-.734 2.056-.49.48-1.178.722-2.066.722h-1.724v-5.33h1.845c.856 0 1.517.219 1.981.656.465.438.698 1.07.698 1.896Zm-1.494.051c0-.488-.097-.85-.29-1.086-.193-.236-.486-.354-.88-.354h-.42v2.986h.322c.438 0 .758-.127.962-.381.204-.254.306-.642.306-1.165ZM20.08 21.533h-1.418v-5.33h3.15v1.156h-1.731v1.017h1.597v1.155H20.08v2.002Z" />
                      <path
                        stroke="#07094A"
                        stroke-linecap="square"
                        stroke-miterlimit="10"
                        stroke-width="2"
                        d="M29 12.2H1v13.067h28V12.2Z" />
                    </svg>
                    <span
                      class="nyk-document-list__content__group__list__item__link__filetype__screen-reader"
                      >pdf ikon</span
                    >
                  </div>
                  <div
                    class="nyk-document-list__content__group__list__item__link__info">
                    <small
                      class="nyk-document-list__content__group__list__item__link__info__date"
                      >{{ item.date }}</small
                    >
                    <p
                      class="nyk-document-list__content__group__list__item__link__info__name">
                      {{ item.title }}
                    </p>
                  </div>
                  <div
                    class="nyk-document-list__content__group__list__item__link__download">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 29 29">
                      <g
                        stroke="#07094A"
                        stroke-miterlimit="10"
                        stroke-width="2">
                        <path
                          stroke-linecap="square"
                          d="M1 20v5.333c0 .708.284 1.386.79 1.886.507.5 1.194.781 1.91.781h21.6c.716 0 1.403-.281 1.91-.781.505-.5.79-1.178.79-1.886V20" />
                        <path d="M14.5 0v20" />
                        <path
                          stroke-linecap="square"
                          d="M7.75 13.333 14.5 20l6.75-6.667" />
                      </g>
                    </svg>
                    <span
                      class="nyk-document-list__content__group__list__item__link__download__screen-reader"
                      >Download fil</span
                    >
                  </div>
                </a>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </template>
  </article>
</template>
