export default async function (body, url) {
  let status = null
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      status = res.status
      if (res.ok) {
        return res.json()
      } else {
        throw new Error("Signup to get contacted didn't work")
      }
    })
    .then((json) => {
      return json.json()
    })
    .catch((err) => {
      console.error(err)
      return err
    })

  return {
    response: response,
    status: status,
  }
}
