<script>
import options from "./SignupStepSelectNewsletters.options"
export default options
</script>

<template>
    <article class="nyk-signup-step">
        <div class="nyk-signup-step__content">
            <div
                class="nyk-signup-step__content__section nyk-signup-step__content__section--half-width nyk-signup-step__content__section--full-width-mobile">
                <div class="nyk-signup-step__header nyk-signup-step-select-newsletters__title-spacing">
                    <h2 class="nyk-signup-step__title">
                        {{ currentStepTexts.title }}
                    </h2>
                    <p class="nyk-signup-step__stepcounter--mobile">{{ stepCounterText }}</p>
                </div>
                <div
                    class="nyk-signup-step__newsletters-options nyk-signup-step__inputs nyk-signup-step__inputs--stacked nyk-signup-step__content__section--full-width-mobile">
                    <template v-for="(field, index) in newsletterFields" :key="index">
                        <div class="nyk-signup-step__checkbox">
                            <input
                                type="checkbox"
                                name="newsletter"
                                :id="`newsletter-${index}`"
                                class="nyk-signup-step__checkbox__input"
                                :checked="field.value"
                                v-model="field.value" />
                            <span class="nyk-signup-step__checkbox__checkmark"></span>
                            <label class="nyk-signup-step__checkbox__label" :for="`newsletter-${index}`">{{ field.field }}</label>
                            <Tooltip :tooltip="field.tooltip" tooltipName="" />
                        </div>
                    </template>
                </div>
            </div>

            <div
                aria-live="polite"
                class="nyk-signup-step__content__section nyk-signup-step__content__section--space-between nyk-signup-step__content__section--half-width nyk-signup-step__content__section--full-width-mobile">
                <div class="nyk-signup-step__summary nyk-signup-step__summary--show-allways" v-html="currentStepTexts.summary"></div>

                <template v-if="displayError">
                    <div class="nyk-signup-step-select-newsletters__error">
                        <span class="nyk-signup-step-select-newsletters__error__text"
                            >Du kan ikke klikke ‘videre’ før du har valgt et nyhedsbrev</span
                        >
                        <div class="nyk-signup-step-select-newsletters__error__icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 21">
                                <g clip-path="url(#a)">
                                    <path
                                        fill="#FFFFFF"
                                        d="M10.233.454c-5.513 0-10 4.486-10 10s4.487 10 10 10c5.514 0 10-4.486 10-10s-4.486-10-10-10Zm-.932 4.063c0-.173.14-.313.312-.313h1.24c.173 0 .313.14.313.313v7.5c0 .172-.14.312-.313.312h-1.24a.313.313 0 0 1-.312-.312v-7.5Zm.932 12.187a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5Z" />
                                </g>
                                <defs>
                                    <clipPath id="a"><path fill="#fff" d="M.233.454h20v20h-20z" /></clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </template>
            </div>

            <SignupStepActions
                :spaceTop="false"
                :previousStepCallback="previousStepCallback"
                :nextStepCallback="handleNextStep"
                :nextStepButtonText="nextStepButtonText"
                :stepCounterText="stepCounterText"
                :hasPreviousStep="hasPreviousStep" />
        </div>
    </article>
</template>
