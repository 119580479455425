import { ref, computed, watch, onMounted } from "vue"
import { useStore } from "vuex"
import debounce from "../../foundation/js/debounce"

export default {
    name: "SearchOverlay",
    props: {
        searchUrl: {
            type: String,
            required: true,
        },
        suggestionUrl: {
            type: String,
            required: true,
        },
        searchResultTitle: {
            type: String,
            required: true,
        },
        searchPlaceholder: {
            type: String,
            required: true,
            default: "Søg",
        },
        searchSuggestionsJson: {
            type: String,
            required: true,
        },
        cardTopJson: {
            type: String,
            required: true,
        },
        cardBottomJson: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const store = useStore()
        const searchInput = ref("")
        const searchOverlay = ref(null)
        const inputIsInFocus = ref(false)

        onMounted(() => {
            searchOverlay.value = document.querySelector(".nyk-search-overlay")

            // attempts at creating initial focus in search input when overlay opened
            // const input = document.querySelector('.nyk-js--search-overlay-input')
            // console.log("🚀 ~ onMounted ~ input:", input)
            // input?.focus()
        })

        const searchResults = computed(() => {
            return store.state.search.suggestions
        })

        const inputIsNotEmpty = computed(() => {
            if (searchInput.value === "") {
                store.dispatch("search/clearSuggestions")
            }
            return searchInput.value !== ""
        })

        const cardTop = computed(() => {
            if (typeof props.cardTopJson === "string") {
                return JSON.parse(props.cardTopJson)
            } else {
                return props.cardTopJson
            }
        })

        const cardBottom = computed(() => {
            if (typeof props.cardBottomJson === "string") {
                return JSON.parse(props.cardBottomJson)
            } else {
                return props.cardBottomJson
            }
        })

        const searchSuggestions = computed(() => {
            if (typeof props.searchSuggestionsJson === "string") {
                return JSON.parse(props.searchSuggestionsJson)
            } else {
                return props.searchSuggestionsJson
            }
        })

        const emptyInputNoFocus = () => {
            searchInput.value = ""
            store.dispatch("menu/searchOverlayMobileNavigateBack", { navigate: true })
        }

        const emptyInput = () => {
            searchInput.value = ""
            const input = searchOverlay.value.querySelector(".nyk-js--search-overlay-input")
            input?.focus()
            store.dispatch("search/clearSuggestions")
        }

        watch(searchInput, (newValue, oldValue) => {
            if (newValue !== oldValue) {
                termInput()
            }
        })

        const termInput = debounce(() => {
            if (searchInput.value) {
                store.dispatch("search/performSearchSuggestions", { apiUrl: props.suggestionUrl, query: searchInput.value })
            }
        }, 500)

        const navigateToSearch = () => {
            window.location.href = props.searchUrl + `?q=${searchInput.value}`
        }

        const setInputInFocus = () => {
            inputIsInFocus.value = true
        }

        const setInputOutOfFocus = () => {
            inputIsInFocus.value = false
        }

        return {
            searchInput,
            emptyInput,
            emptyInputNoFocus,
            inputIsNotEmpty,
            navigateToSearch,
            searchResults,
            inputIsInFocus,
            setInputInFocus,
            setInputOutOfFocus,
            termInput,
            searchSuggestionsJson:
                typeof props.searchSuggestionsJson === "string" ? JSON.parse(props.searchSuggestionsJson) : props.searchSuggestionsJson,
            cardTopJson: typeof props.cardTopJson === "string" ? JSON.parse(props.cardTopJson) : props.cardTopJson,
            cardBottomJson: typeof props.cardBottomJson === "string" ? JSON.parse(props.cardBottomJson) : props.cardBottomJson,
        }
    },
}
