import {createStore} from 'vuex'

import main from './modules/store.main'
import menu from './modules/store.menu'
import search from './modules/store.search'
import jobs from './modules/store.jobs'

const store = createStore({
    modules: {
        main,
        menu,
        search,
        jobs
    }
})

export default store