<script>
import options from "./DynamicTable.options"
export default options
</script>

<template>
  <article class="nyk-dynamic-table nyk-table">
    <div class="nyk-table__container">
      <table class="nyk-table__container__table">
        <thead class="nyk-table__container__table__header">
          <tr class="nyk-table__container__table__header__row">
            <template v-for="(item, index) in headers" :key="index">
              <th
                class="nyk-table__container__table__header__row__item"
                :class="item.styles">
                {{ item.name }}
              </th>
            </template>
          </tr>
        </thead>

        <tbody class="nyk-table__container__table__body">
          <template v-for="(row, indexRow) in rows" :key="indexRow">
            <tr class="nyk-table__container__table__body__row">
              <template v-for="(item, indexItem) in row" :key="indexItem">
                <td
                  class="nyk-table__container__table__body__row__item"
                  :class="item.styles">
                  <template v-if="item.href">
                    <a :href="item.href">
                      {{ item.name }}
                    </a>
                  </template>
                  <template v-else>
                    {{ item.name }}
                  </template>

                  <template v-if="item.downloadIcon">
                    <template v-if="item.href">
                      <a :href="item.href"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 29 29">
                          <g
                            stroke="#07094A"
                            stroke-miterlimit="10"
                            stroke-width="2">
                            <path
                              stroke-linecap="square"
                              d="M1 20v5.333c0 .708.284 1.386.79 1.886.507.5 1.194.781 1.91.781h21.6c.716 0 1.403-.281 1.91-.781.505-.5.79-1.178.79-1.886V20" />
                            <path d="M14.5 0v20" />
                            <path
                              stroke-linecap="square"
                              d="M7.75 13.333 14.5 20l6.75-6.667" />
                          </g>
                        </svg>
                      </a>
                    </template>
                    <template v-else
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 29 29">
                        <g
                          stroke="#07094A"
                          stroke-miterlimit="10"
                          stroke-width="2">
                          <path
                            stroke-linecap="square"
                            d="M1 20v5.333c0 .708.284 1.386.79 1.886.507.5 1.194.781 1.91.781h21.6c.716 0 1.403-.281 1.91-.781.505-.5.79-1.178.79-1.886V20" />
                          <path d="M14.5 0v20" />
                          <path
                            stroke-linecap="square"
                            d="M7.75 13.333 14.5 20l6.75-6.667" />
                        </g>
                      </svg>
                    </template>
                  </template>
                </td>
              </template>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </article>
</template>
