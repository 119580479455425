<script>
import options from "./FilterTabs.options"
export default options
</script>

<template>
    <div class="nyk-filter-wrapper">
        <div class="nyk-filter-tabs" role="tablist" ref="tabContainer">
            <span class="nyk-filter-tabs__line" ref="lineElement"></span>
            <template v-for="(tab, index) in tabs" :key="index">
                <div class="nyk-filter-tabs__tab">
                    <button
                        role="tab"
                        :aria-selected="tab.id === activeTab"
                        :id="tab.id"
                        v-on:click="changeTab(tab.id)"
                        class="nyk-filter-tabs__tab__button">
                        <span class="nyk-filter-tabs__tab__button__text">
                            {{ tab.title }}
                        </span>
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>
